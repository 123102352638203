<template>
  <div class="closed-invoice-edit">
    <custom-validate-input :label="$t('closed.labels.description')"
                           align="right"
                           rtl
                           :is-empty="true"
                           :value="closeDescription"/>
    <div class="confirm-table">
      <draggable-dynamic-table ref="ClosedInvoiceProfile"
                               v-if="data.length"
                               :in-modal="true"
                               :active-grid="true"
                               :fix-screen="true"
                               :options="options"
                               :columns="columnsLabel"
                               @suggestPrompt:opened="selectTimePromptStatus = true"
                               @row:deleted="handleDeleteRow"
                               v-model="data"/>
    </div>

    <!-- insert new times prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="selectTimePromptStatus"
      @close="selectTimePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('emitTimeSelected')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('closed.labels.edit') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="selectTimePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <sales-closed-calendar @selected="handleSelectClosedTimes($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <vs-button v-if="this.checkUserPermissions('invoice.delete')"
               id="deleteClosedInvoiceBTN"
               class="useral-action-button"
               @click="$refs.deleteCloseInvoiceConfirmation.showDialog()"/>

    <custom-dialog ref="deleteCloseInvoiceConfirmation"
                   :title="$t('closed.confirmations.delete.title')"
                   :body="$t('closed.confirmations.delete.body')"
                   @accept="deleteClosedInvoice"/>


    <vs-button id="editClosedInvoiceBTN" class="useral-action-button" @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {deleteClosedInvoice, editClosedInvoice, getClosedInvoice} from "../../../../http/requests/closedInvoices";
  import CustomValidateInput from "../../../../components/customInput/customValidateInput";
  import CustomDialog from "../../../../components/customDialog/customDialog";
  import {checkUserPermissions} from "../../../../assets/js/functions";
  import SalesClosedCalendar from "../calendar/salesClosedCalendar";
  import CustomIcon from "../../../../components/customIcon/customIcon";

  export default {
    name: "editClosedInvoice",
    components: {CustomIcon, SalesClosedCalendar, CustomDialog, CustomValidateInput},
    metaInfo () {
      return {
        title: this.$t('closed.edit.title')
      }
    },
    data () {
      return {
        selectTimePromptStatus: false,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'delete',
            i18n: 'closed.table.header.delete',
            width: '70px',
            minWidth: 70,
            /*sortable: true,*/
            actions: true,
            showAction: 'always',
            action: {
              color: 'danger',
              icon: 'icon-x',
              iconPack: 'feather',
              type: 'button'
            },
            event: 'row:deleted'
          },
          {
            field: 'timeReserve',
            i18n: 'closed.table.header.timeReserve',
            width: '150px',
            minWidth: 150,
          },
          {
            field: 'date',
            i18n: 'closed.table.header.date',
            align: 'center',
            width: 'calc(25%)',
            minWidth: 150,
            /*sortable: true,*/
          },
          {
            field: 'name',
            i18n: 'closed.table.header.courtName',
            align: 'center',
            width: 'calc(40%)',
            minWidth: 150,
            suggestPrompt: true,
            footer: {}
          },
          {
            field: 'rowNumber',
            i18n: 'closed.table.header.row',
            align: 'center',
            width: '70px',
            minWidth: 70,
            autoIncrement: true,
            footer: {
              type: 'auto-counter'
            }
          }
        ],
        firstVisit: true,
        firstVisitTimer: 0,
        users: [],
        data: [],
        closeDescription: {
          value: '',
          isValid: false
        },
        selectedIndex: 0,
        total_count: 0,
        page: 0,
        filters: [],
        actions: [
          {
            toolbar: [
              {
                id: 'editClosedInvoiceBTN',
                icon: 'SAVE',
                iconPack: 'useral',
                color: 'success',
                badge: {
                  value: 0,
                },
                permission: 'invoice.create'
              }
            ],
            leftToolbar: [
              {
                id: 'deleteClosedInvoiceBTN',
                icon: 'TRASH',
                iconPack: 'useral',
                color: 'danger',
                permission: 'closed_invoice.delete'
              },
              {
                id: {name: 'closedInvoiceProfile'},
                type: 'link',
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created () {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
      }, 100)

      this.getClosedInvoice()
    },
    methods: {
      checkUserPermissions(permissions) {
        return checkUserPermissions(permissions)
      },
      getClosedInvoice () {
        getClosedInvoice(this.$route.params.id).then(response => {
          const closedInvoice = response.data.data
          this.closeDescription = {
            value: closedInvoice.note,
            isValid: true
          }

          if (closedInvoice.lines.length > 0) {
            closedInvoice.lines.forEach(item => {
              this.data.push({
                id: item.id,
                index: `${item.date.split(' ')[0]}_${item.time.id}_${item.court.id}`,
                name: item.court.name,
                date: item.date.split(' ')[0],
                timeReserve: `${item.time.start_time.substr(0, 5)} ${this.$t('closed.labels.until')} ${item.time.end_time.substr(0, 5)} `,
                court: item.court,
                termId: item.term.id,
                time: item.time
              })
            })
          }
        })
      },
      handleSelectClosedTimes (selectedTimes) {
        Object.keys(selectedTimes).forEach((item) => {
          const selectedTimeIndex = this.data.map(e => e.index).indexOf(item)
          if (selectedTimeIndex === -1) {
            const selectedTime = selectedTimes[item]
            this.data.push({
              id: this.data.length,
              index: item,
              name: selectedTime.court.court_name,
              date: selectedTime.date,
              timeReserve: `${selectedTime.time.start_time.substr(0, 5)} ${this.$t('closed.labels.until')} ${selectedTime.time.end_time.substr(0, 5)} `,
              court: selectedTime.court,
              termId: selectedTime.termId,
              time: selectedTime.time
            })
          }
          this.selectTimePromptStatus = false
        })

      },
      sendData() {
        let errorNum = 0

        if (this.data.length === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('closed.notifications.noTime'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
          errorNum++
          return false
        }

        if (!this.closeDescription.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('closed.notifications.descriptionWrong'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
          errorNum++
          return false
        }

        const products = []
        this.data.forEach((product) => {
          if (product.id && product.id !== 0) {
            products.push({
              time_id: product.time.id,
              court_id: product.court.id,
              term_id: product.termId,
              date: product.date,
            })
          }
        })

        if (errorNum === 0) {
          const data = {
            note: this.closeDescription.value,
            lines: products,
          }
          editClosedInvoice(this.$route.params.id, data).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('closed.notifications.edit.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400
            })
            this.$router.replace({name: 'closedInvoices'})
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'user_id': '',
              }
              switch (error.response.status) {
                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('closed.notifications.edit.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      },
      handleDeleteRow(row) {
        if (row) {
          const index = this.data.map((elm) => {
            return elm.id
          }).indexOf(row.id)
          if (index > -1 && row.id !== 0 && this.data.length > 1) {
            this.selectedIndex = 0
            this.data.splice(index, 1)
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('closed.notifications.noTimeAfterDelete'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        }
      },
      deleteClosedInvoice() {
        deleteClosedInvoice(this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('closed.notifications.delete.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400
          })
          this.$router.replace({name: 'closedInvoices'})
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
              case 409:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('closed.notifications.cantDelete'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('closed.notifications.delete.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss">
  .closed-invoice-edit {
    height: calc(100vh - 165px);
    background-color: #ffffff;
    padding: 0.5rem;
    border: 1px solid #cdcdcd;
    border-top: 0;
    border-radius: 0 0 0.5rem 0.5rem;

    .confirm-table {
      height: calc(100% - 65px);


      .new-suggest-modal-button {
        display: none;
      }

      .suggest-modal-button {
        right: 7px !important;
      }
    }
  }
</style>
